import React from 'react';

import Link from 'components/consumer/Link';
import IconBell from 'components/icons/iconBell';
import IconMail from 'components/icons/iconMail';
import useUserData from 'data-hooks/useUserData';

import { colorGrayLight3 } from 'styles/layout/colors';

const MobileNavbarFooter = () => {
  const { isTradeUser } = useUserData();

  return (
    <div className="text-center">
      <h2 className="text-lg font-bold">Need a Hand?</h2>
      <p className="text-sm text-gray flex flex-col content-center">
        {isTradeUser ? (
          <>
            <span
              className="flex flex-col content-center mb-3"
              aria-label="Trade user hours of business"
            >
              <span>Mon-Fri: 7:00 am - 4:00 pm PST</span>
              <span>Sat-Sun: Closed</span>
            </span>

            <span
              className="flex flex-col content-center"
              aria-label="Trade user telephone number"
            >
              <span>
                Call Us:{' '}
                <a className="text-brand" href="tel:+18552084414">
                  (855) 208-4414
                </a>
              </span>
            </span>
          </>
        ) : (
          <>
            <span
              className="flex flex-col content-center mb-3"
              aria-label="Hours of business"
            >
              <span>Mon-Fri: 6:00 am - 5:00 pm PST</span>
              <span>Sat-Sun: 8:00 am - 4:00 pm PST</span>
            </span>

            <span
              className="flex flex-col content-center"
              aria-label="Telephone numbers"
            >
              <span>
                Call Us:{' '}
                <a className="text-brand" href="tel:+18882820842">
                  (888) 282-0842
                </a>
              </span>
              <span>
                Text Us:{' '}
                <a className="text-brand" href="sms:+12136006313">
                  (213) 600-6313
                </a>
              </span>
            </span>
          </>
        )}
      </p>

      <div className="flex flex-row justify-evenly mx-auto mt-4 max-w-[400px]">
        <Link
          className="text-sm font-bold text-gray rounded-[3px] flex flex-col items-center px-2 py-4 text-center hover:underline"
          to="https://help.fiore.buzz"
        >
          <div>
            <IconBell color={colorGrayLight3} />
          </div>
          FAQ
        </Link>

        <Link
          className="text-sm font-bold text-gray rounded-[3px] flex flex-col items-center px-2 py-4 text-center hover:underline-link"
          to="https://fiore.buzz/contact-us/"
        >
          <div>
            <IconMail color={colorGrayLight3} />
          </div>
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default MobileNavbarFooter;
