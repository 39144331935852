const newMobileStaticNavItems = [
  {
    id: 'categories',
    links: [
      {
        id: 'sofas-and-sectionals',
        label: 'Sofas & Sectionals',
        links: [
          {
            label: 'All Sofas & Sectionals',
            id: 'all-sofas-and-sectionals',
            to: '/sofas-and-sectionals',
          },
          {
            label: 'Sofas & Loveseats',
            id: 'sofas-and-loveseats',
            to: '/sofas-and-sectionals/?category=sofas%2Cloveseats',
          },
          {
            label: 'Sectionals',
            id: 'sectionals',
            to: '/sofas-and-sectionals/?category=sectionals',
          },
          {
            label: 'Sleeper Sofas',
            id: 'sleeper-sofas',
            to: '/sofas-and-sectionals/?category=sleeper%20sofas',
          },
          {
            label: 'Storage Sofas',
            id: 'storage-sofas',
            to: '/sofas-and-sectionals/?category=sofas&features=storage',
          },
          {
            label: 'Storage Sectionals',
            id: 'storage-sectionals',
            to: '/sofas-and-sectionals/?category=sectionals&features=storage',
          },
          {
            label: 'Modular Sofas',
            id: 'modular-sofas',
            to: '/modular-sofas',
          },
          {
            label: 'Grand Sofas',
            id: 'grand-sofas',
            to: '/sofas-and-sectionals/?category=grand%20sofas',
          },
          {
            label: 'Apartment Sofas',
            id: 'apartment-sofas',
            to: '/sofas-and-sectionals/?category=sofas&features=small%20space',
          },
          {
            label: 'Outdoor Sofas',
            id: 'outdoor-sofas',
            to: '/outdoor-furniture/?category=outdoor%20sofas',
          },
          {
            label: 'Daybeds & Chaises',
            id: 'daybeds-and-chaises',
            to: '/sofas-and-sectionals/?category=chaises%20%26%20daybeds',
          },
          {
            label: 'Ottomans, Poufs and Benches',
            id: 'ottomans-poufs-and-benches',
            to: '/ottomans?category=ottomans%2Cbenches',
          },
        ],
      },
      {
        id: 'chairs',
        label: 'Chairs',
        links: [
          {
            label: 'All Chairs',
            id: 'all-chairs',
            to: '/chairs',
          },
          {
            label: 'Accent Chairs',
            id: 'accent-chairs',
            to: '/chairs/?category=accent%20chairs',
          },
          {
            label: 'Apartment Chairs',
            id: 'apartment-chairs',
            to: '/chairs/?features=small%20space',
          },
          {
            label: 'Swivel Chairs',
            id: 'swivel-chairs',
            to: '/chairs/?category=swivel%20chairs',
          },
          {
            label: 'Rocking Chairs & Recliners',
            id: 'rocking-chairs-and-recliners',
            to: '/chairs?category=rocking%20chairs%2Crecliner%20chairs',
          },
          {
            label: 'Dining Chairs',
            id: 'dining-chairs',
            to: '/chairs?category=dining%20chairs',
          },
          {
            label: 'Bar & Counter Stools',
            id: 'bar-and-counter-stools',
            to: '/chairs/?category=counter%20stools',
          },
          {
            label: 'Office Chairs',
            id: 'office-chairs',
            to: '/chairs/?category=office%20chairs',
          },
          {
            label: 'Outdoor Chairs',
            id: 'outdoor-chairs',
            to: '/chairs/?category=outdoor%20chairs',
          },
          {
            label: 'Ottomans, Poufs and Benches',
            id: 'ottomans-poufs-and-benches',
            to: '/ottomans?category=ottomans%2Cbenches',
          },
        ],
      },
      {
        label: 'Bedroom',
        id: 'bedroom',
        links: [
          {
            label: 'All Bedroom',
            id: 'all-bedroom',
            to: '/bedroom',
          },
          {
            label: 'Beds',
            id: 'beds',
            to: '/bedroom/?category=beds',
          },
          {
            label: 'Nightstands',
            id: 'nightstands',
            to: '/bedroom/?category=nightstands',
          },
          {
            label: 'Dressers & Chests',
            id: 'dressers-chests',
            to: '/bedroom/?category=dressers%20%26%20chests',
          },
          {
            label: 'Benches',
            id: 'benches',
            to: '/bedroom/?category=benches',
          },
          {
            label: 'Rugs',
            id: 'rugs',
            to: '/decoration-accessories/?category=rugs',
          },
          {
            label: 'Pillows',
            id: 'pillows',
            to: '/decoration-accessories/?category=pillows%2Cthrows',
          },
          {
            label: 'Throws',
            id: 'throws',
            to: '/bedroom/?category=throws',
          },
          {
            label: 'Lighting',
            id: 'lighting',
            to: '/decoration-accessories/?category=lighting',
          },
          {
            label: 'Decor',
            id: 'decor',
            to: '/decoration-accessories/',
          },
          {
            label: 'Pet Beds',
            id: 'pet-beds',
            to: '/bedroom/?category=pet%20beds',
          },
          {
            label: 'Mattresses',
            id: 'mattresses',
            // TODO: URL for mattresses page
            to: '',
          },
        ],
      },
      {
        id: 'strorage',
        label: 'Storage',
        links: [
          {
            label: 'All Storage',
            id: 'all-storage',
            to: '/all-storage',
          },
          {
            label: 'TV Stands & Media Consoles',
            id: 'tv-stands-and-media-consoles',
            to:
              '/all-storage/?category=tv%C2%A0stands%C2%A0%26%C2%A0media%C2%A0consoles',
          },
          {
            label: 'Bookcases & Shelving',
            id: 'bookcases-and-shelving',
            to: '/all-storage/?category=bookcases%C2%A0%26%C2%A0shelving',
          },
          {
            label: 'Bar Carts & Storage Cabinets',
            id: 'bar-carts-and-storage-cabinets',
            to: '/bar-carts-cabinets',
          },
          {
            label: 'Storage Sofas & Ottomans',
            id: 'storage-sofas-and-ottomans',
            to:
              '/sofas-and-sectionals/?features=storage/ottomans/?category=ottomans&features=storage',
          },
        ],
      },
      {
        label: 'Outdoor',
        id: 'outdoor',
        links: [
          {
            label: 'All Outdoor',
            id: 'all-outdoor',
            to: '/outdoor-furniture/',
          },
          {
            label: 'Outdoor Sofas',
            id: 'outdoor-sofas',
            to: '/outdoor-sofas/',
          },
          {
            label: 'Outdoor Chairs',
            id: 'outdoor-chairs',
            to: '/outdoor-chairs/',
          },
          {
            label: 'Outdoor Tables',
            id: 'outdoor-tables',
            to: '/outdoor-tables/',
          },
        ],
      },
      {
        id: 'tables',
        label: 'Tables',
        links: [
          {
            label: 'All Tables',
            id: 'all-tables',
            to: '/all-tables',
          },
          {
            label: 'Dining Tables',
            id: 'dining-tables',
            to: '/all-tables/?category=dining%20tables',
          },
          {
            label: 'Coffee Tables',
            id: 'coffee-tables',
            to: '/all-tables/?category=coffee%20tables',
          },
          {
            label: 'Side, End, & Accent Tables',
            id: 'side-end-and-accent-tables',
            to: '/all-tables/?category=end%20%26%20side%20tables',
          },
          {
            label: 'Entry & Console Tables',
            id: 'entry-and-console-tables',
            to: '/all-tables/?category=console%20%26%20entry%20tables',
          },
          {
            label: 'Expandable Tables',
            id: 'expandable-tables',
            to: '/',
          },
          {
            label: 'Desks',
            id: 'desks',
            to: '/all-tables/?category=desks',
          },
          {
            label: 'Outdoor Tables',
            id: 'outdoor-tables',
            to:
              '/outdoor-furniture/?category=outdoor%20tables/all-tables/?category=outdoor%20tables',
          },
        ],
      },
      {
        label: 'Decor',
        id: 'decor',
        links: [
          {
            label: 'All Decor',
            id: 'all-decor',
            to: '/decoration-accessories',
          },
          {
            label: 'Rugs',
            id: 'rugs',
            to: '/decoration-accessories/?category=rugs',
          },
          {
            label: 'Pillows',
            id: 'pillows',
            to: '/decoration-accessories/?category=pillows',
          },
          {
            label: 'Throws',
            id: 'throws',
            to: '/decoration-accessories/?category=throws',
          },
          {
            label: 'Poufs & Cubes',
            id: 'poufs-and-cubes',
            to: '/decoration-accessories/?category=poufs%20%26%20cubes',
          },
          {
            label: 'Lighting',
            id: 'lighting',
            to: '/decoration-accessories/?category=lighting',
          },
          {
            label: 'Wall Decor',
            id: 'wall-decor',
            to: '/decoration-accessories/?category=wall%20decor',
          },
          {
            label: 'Wallpaper',
            id: 'wallpaper',
            to: '/decoration-accessories/?category=wallpaper',
          },
          {
            label: 'Planters',
            id: 'planters',
            to: '/decoration-accessories/?category=planters',
          },
          {
            label: 'Mirrors',
            id: 'mirrors',
            to: '/decoration-accessories/?category=mirrors',
          },
          {
            label: 'Tableware',
            id: 'tableware',
            to: '/decoration-accessories/?category=tableware',
          },
          {
            label: 'Pet Beds',
            id: 'pet-beds',
            to: '/decoration-accessories/?category=pet%20beds',
          },
        ],
      },
      {
        label: 'Sale',
        id: 'sale',
        links: [
          {
            label: 'Clearance',
            id: 'clearance',
            to: '/clearance',
          },
        ],
      },
      {
        id: 'rooms',
        label: 'Rooms',
        links: [
          {
            label: 'All Rooms',
            id: 'all-rooms',
            to: '/shop',
          },
          {
            label: 'Living Room',
            id: 'living-room',
            to: '/living-room-furniture',
          },
          {
            label: 'Dining Room',
            id: 'dining-room',
            to: '/dining-room-furniture',
          },
          {
            label: 'Bedroom',
            id: 'bedroom',
            to: '/bedroom',
          },
          {
            label: 'Home Office',
            id: 'home-office',
            to: '/home-office-furniture',
          },
          {
            label: 'Outdoor',
            id: 'outdoor',
            to: '/outdoor-furniture',
          },
        ],
      },
    ],
  },
  {
    id: 'design-services-group',
    links: [
      {
        label: 'Customer Photos',
        id: 'customer-photos',
        to: '/customer-photos/',
        subLabel: 'Photos from real Joybird customers!',
        images: [
          {
            name: 'Home Photo 1',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-photo-1.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Photo 2',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-photo-2.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Photo 3',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-photo-3.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Photo 4',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-photo-4.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Photo 5',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-photo-5.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
        ],
      },
      {
        label: 'Materials & Swatches',
        id: 'virtual-swatch-kit',
        to: '/swatches/',
        subLabel:
          'Colors, textures and materials - the possibilities are endless.',
        images: [
          {
            name: 'Home Swatch 1',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-1.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Swatch 2',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-2.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Swatch 3',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-3.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Swatch 4',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-4.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Swatch 5',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-5.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Swatch 6',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-6.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
        ],
      },
      {
        label: 'Free Design Services',
        id: 'free-design-services',
        to: '/free-design-services/',
        subLabel: 'Get expert design help for free!',
        backgroundImage: {
          name: 'Free design services image',
          src:
            'https://joybird2.imgix.net/user-uploads-staging/design-services-mobile-1581625113428.jpeg',
          imgProps: {
            height: '100',
            width: '260',
            fit: 'crop',
            crop: 'focalpoint',
            fpX: '0.5',
            fpY: '0.5',
            fpZoom: '1',
          },
        },
      },
      {
        label: 'Lookbooks',
        id: 'lookbooks',
        to: '/lookbooks/',
      },
      {
        label: 'Showrooms',
        id: 'showrooms',
        to: '/showrooms/',
      },
      {
        label: 'Joybird Live',
        id: 'joybird-live',
        to: '/live/',
      },
      {
        label: 'Room Visualizer',
        id: 'room-refresh',
        to: 'https://roomrefresh.fiore.buzz/',
      },
      {
        label: 'Videos',
        id: 'videos',
        to: '/videos/',
      },
      {
        label: 'Blog',
        id: 'blog',
        to: '/blog/',
      },
      {
        label: 'Collections',
        id: 'collections',
        to: '/collections/',
      },
    ],
  },
  {
    id: 'group-2',
    links: [
      {
        label: 'Resources',
        id: 'resources',
        links: [
          {
            label: 'Warranty',
            id: 'warranty',
            to: '/warranty/',
          },
          {
            label: 'Shipping',
            id: 'shipping',
            to: '/shipping-and-delivery/',
          },
          {
            label: 'Returns',
            id: 'returns',
            to: '/returns/',
          },
          {
            label: 'Product Care',
            id: 'product-care',
            to: '/product-care/',
          },
          {
            label: 'Fit Guide',
            id: 'fit-guide',
            to: '/fit-guide/',
          },
          {
            label: 'FAQ',
            id: 'faq',
            to: 'https://help.fiore.buzz/',
          },
          {
            label: 'Financing',
            id: 'financing',
            to: '/product-financing/',
          },
          {
            label: 'Product Safety',
            id: 'product-safety',
            to: '/product-safety/',
          },
          {
            label: 'Free Fabric Samples',
            id: 'free-fabric-samples',
            to: '/samples/',
          },
        ],
      },
      {
        label: 'About',
        id: 'about',
        links: [
          {
            label: 'Story',
            id: 'story',
            to: '/about-us/',
          },
          {
            label: 'Workshop',
            id: 'workshop',
            to: '/the-factory/',
          },
          {
            label: 'Reviews',
            id: 'reviews',
            to: '/reviews/',
          },
          {
            label: 'One Tree Planted',
            id: 'one-tree-planted',
            to: '/onetreeplanted/',
          },
          {
            label: 'Press',
            id: 'press',
            to: '/press/',
          },
          {
            label: 'Careers',
            id: 'careers',
            to: '/careers/',
          },
        ],
      },
      {
        label: 'Support',
        id: 'support',
        links: [
          {
            label: 'Track Your Order',
            id: 'track-your-order',
            to: '/customer/orders/',
          },
          {
            label: 'Contact Us',
            id: 'contact-us',
            to: 'https://fiore.buzz/contact-us/',
          },
          {
            label: 'Trade & Business',
            id: 'trade-and-business',
            to: '/business/',
          },
        ],
      },
    ],
  },
  {
    id: 'login',
    links: [
      {
        label: 'Login',
        id: 'login',
        to: '/customer/dashboard/',
      },
    ],
  },
];

export default newMobileStaticNavItems;
